<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-70 md-small-size-100 global-layout-center app-top-home">
        <div class="md-layout md-alignment-center">
          <div class="md-layout-item md-size-33">
            <md-card class="md-elevation-0">
              <md-card-media> 
                <img class="app-profile-picture" v-bind:src="profile" alt="Profile of Matt Choi">
              </md-card-media>
            </md-card>
          </div>
          <div class="md-layout-item md-size-66">
            <md-card class="md-elevation-0">
              <md-card-content class="fade-in-image">
                <h1 class="app-home-title">Hello!</h1>
                <p>
                  Thank you for visiting my website.  Here you can find information regarding my prior projects and experiences.
                  <br/><br/>
                  If you want to get in touch, contact me!
                </p>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
      
    <div class="md-layout">
      <div class="md-layout-item md-size-33 global-layout-center">
        <md-icon class="app-primary md-size-4x app-home-icons">cloud</md-icon>
        <h3 class="app-icon-subtitle">Cloud Engineer</h3>
      </div>
      <div class="md-layout-item md-size-33 global-layout-center">
        <md-icon class="app-primary md-size-4x app-home-icons">code</md-icon>
        <h3 class="app-icon-subtitle">Developer</h3>
      </div>
      <div class="md-layout-item md-size-33 global-layout-center">
        <md-icon class="app-primary md-size-4x app-home-icons">lightbulb</md-icon>
        <h3 class="app-icon-subtitle">Problem Solver</h3>
      </div>
    </div>

    <div class="md-layout md-alignment-top">
      <div class="md-layout-item md-size-33">
        <md-card class="app-skills-card md-elevation-1" v-in-viewport>
          <md-card-content class="fade-in-image">
            <p class="global-text-center">Able to deliver secure, scalable, performant, highly available, and cost-effective architectures with years of experience and certifications in AWS & Azure</p>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-size-33">
        <md-card class="app-skills-card md-elevation-1" v-in-viewport>
          <md-card-content class="fade-in-image">
            <p class="global-text-center">Driven to take on new challenges to define, develop, and iterate ideas into tested and efficient code that is ready to contribute business value</p>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-size-33">
        <md-card class="app-skills-card md-elevation-1" v-in-viewport>
          <md-card-content class="fade-in-image">
            <p class="global-text-center">Equipped with a strong communication background and a passion for collaborating, delivering, and solving problems as a team</p>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';
@import '@/assets/animations.scss';

.app-home-title {
  font-family: 'Heebo', sans-serif;
  font-size:92px;
  font-weight:100;
}

.app-skills-card {
  margin:20px 40px;
  font-family: 'Heebo', sans-serif;
  visibility:hidden;
}

.md-app {
  max-height: 400px;
  border: 1px solid rgba(black, .12);
}

.app-profile-picture {
  border-radius: 50%;
}

.app-icon-subtitle {
  text-align: center;
}

.app-home-icons {
  width:100%;
}

p {
  font-family: 'Heebo', sans-serif;
  font-weight:400;
  font-size:16px;
}

h3 {
  font-family: 'Heebo', sans-serif;
  font-weight:100;
  font-size:32px;
}

</style>

<script>
import profile from "../../public/profile.jpg"

export default {
  name: 'Home',
  data: () => ({
    profile: profile
  })
}
</script>
