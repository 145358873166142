<template>
  <div class="page-container">
    <md-app md-waterfall md-mode="fixed" class="app">
      <md-app-toolbar class="md-dense md-primary app-header">
        <div class="md-toolbar-row">
          <div class="md-toolbar-section-start">
            <a class="app-no-underline" @click="selectTab(tabs.filter(tab => tab.name == 'Home')[0])">
              <span class="md-title header-title">Matt Choi</span>
            </a>
            <md-button 
              v-for="tab in tabs" 
              :key="tab.name"
              :class="{'active-tab': tab.selected }" 
              @click="selectTab(tab)"
              class="md-primary md-raised">
              {{ tab.name }}
            </md-button>
          </div>

          <div class="md-toolbar-section-end">
            <a href="https://github.com/mattchoi2" target="_blank">
              <md-avatar>
                <img class="toolbar-img" src="../public/github.png" alt="Github profile picture">
              </md-avatar>
            </a>
            <a href="https://www.linkedin.com/in/matthew-choi-16a8b5124/" target="_blank">
              <md-avatar>
                <img class="toolbar-img" src="../public/linkedin.png" alt="LinkedIn icon">
              </md-avatar>
            </a>
            <md-button class="md-icon-button">
              <md-icon>more_vert</md-icon>
            </md-button>
          </div>
        </div>
      </md-app-toolbar>
      <md-app-content class="md-scrollbar">
        <div class="app-content">
          <router-view />
        </div>
        <app-footer/>
      </md-app-content>
    </md-app>
  </div>
</template>

<script>
import AppFooter from './components/AppFooter';

export default {
  name: "App",
  components: {
    "app-footer": AppFooter
  },
  data: () => ({
    tabs: [
      { 
        name: "Home",
        route: "/",
        selected: true
      },
      {
        name: "About",
        route: "/About",
        selected: false
      },
      {
        name: "Photos",
        route: "/Photos",
        selected: false
      }
    ]
  }),
  methods: {
    selectTab(tab) {  
      this.unselectAllTabs()
      tab.selected = true;
      if (this.$route.path !== tab.route) {
        this.$router.replace({ path: tab.route })
      }
    },
    unselectAllTabs() {
      for (let i = 0; i < this.tabs.length; i++) {
        this.tabs[i].selected = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.md-app {
  max-height: 100vh;
  overflow: auto;
}

.app-content {
  min-height:100vh;
}

.app-no-underline:hover {
  text-decoration: none;
  cursor:pointer;
}

.toolbar-img {
  padding:5px;
}

.md-avatar {
  margin: inherit;
}

.md-app-content {
  padding:0px;
  border:0px;
}

.app-header {
  background-color: $header;
}

.header-title {
  padding-right: 30px;
}


</style>
