<template>
  <div>
    <div class="app-footer-base md-elevation-5">
      <div class="md-layout">
        <div class="md-layout-item md-size-80 md-alignment-center">
          <div class="app-primary-text md-layout">
            <div class="md-layout-item md-size-50">
              <h3 class="app-footer-header">Matt Choi</h3>
            </div>
            <div class="md-layout-item md-size-50">
              <h3 class="app-footer-header">Quick links</h3>
              <a class="app-link" href="https://github.com/mattchoi2" target="_blank">GitHub</a><br/>
              <a class="app-link" href="https://www.linkedin.com/in/matthew-choi-16a8b5124/" target="_blank">LinkedIn</a><br/><br/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-footer-gutter app-secondary-text">
      <div class="md-layout">
        <div class="md-layout-item md-size-80 md-alignment-center">
          <p>Made with <span class="red">❤</span> in Philadelphia | Copyright {{ today.getFullYear() }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({
    today: Date
  }),
  created() {
    this.today = new Date();
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/_shared.scss';

.app-footer-header {
  font-size:20px;
  font-weight:100;
}

.md-alignment-center {
  margin:auto;
}

.app-link:visited {
  color:white;
}

.app-footer-base {
  background-color: $primary;
  width: 100%;
  margin-top:20px;
}

.app-footer-gutter {
  background-color: $footerGutter;
}

.red {
  color:red;
}
</style>